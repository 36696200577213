var prodcat = prodcat || {};

(function ($) {
  Drupal.behaviors.filterableShadesProductCardV1 = {
    isMobile: false,
    selector: {
      container: '.js-filterable-find-shades-block',
      productCard: '.js-filterable-shades-product-card',
      prdCardShadeName: '.js-filterable-shades-product-card-shade-name',
      prdCardShadeDesc: '.js-filterable-shades-product-card-shade-desc',
      prdCardPrdImage: '.js-product-brief-image',
      prdCardShadeSwatch: '.js-filterable-shades-product-card-shade-swatch',
      prdCardImage: '.js-filterable-shades-product-card-image',
      prdCardCloseBtn:'.js-filterable-shades-product-card-close',
      prdCardPrdAddToBag: 'js-add-to-cart',
      item: '.js-filterable-shades-grid-item',
      prdCardResult: '.js-filterable-shades-grids-formatter-result',
      sppLink: 'js-spp-link',
      overlayFindShade: '.js-product-find-shade-overlay-v1',
      vtoButton: '.js-product-vto-btn',
      foundationVtoButton: '.js-foundation-vto-btn',
      shadeFinderResultParent: '.js-product-full-v3',
    },
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.setup(context);
    },
    setup: function (context) {
      var self = this;
      var $results = $(self.selector.productCard, context);
      $results.each(function () {
        var $this = $(this);
        $this.results = {};
        self.getDom($this);
        self.setEvents($this);
      });
    },
    getDom: function ($that) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$container = $that.closest(self.selector.container);
      $that.nodes.$prdCardImage = $(self.selector.prdCardImage, $that);
      $that.nodes.$prdCardCloseBtn = $(self.selector.prdCardCloseBtn, $that);
      $that.nodes.$prdCardShadeName = $(self.selector.prdCardShadeName, $that);
      $that.nodes.$prdCardShadeDesc = $(self.selector.prdCardShadeDesc, $that);
      $that.nodes.$prdCardPrdImage = $(self.selector.prdCardPrdImage, $that);
      $that.nodes.$prdCardShadeSwatch = $(self.selector.prdCardShadeSwatch, $that);
      $that.nodes.$sppLink = $(self.selector.sppLink, $that);
      $that.nodes.$items = $(self.selector.item, $that.nodes.$container);
      $that.nodes.$prdCardResult = $(self.selector.prdCardResult, $that.nodes.$container);
      $that.nodes.$vtoButton = $(self.selector.vtoButton, $that);
      $that.nodes.$foundationVtoButton = $(self.selector.foundationVtoButton, $that);
      $that.nodes.$overlayFindShade = $that.nodes.$container.closest(self.selector.overlayFindShade);
      $that.nodes.$shadeFinderResultParent = $that.nodes.$container.closest(self.selector.shadeFinderResultParent);
    },
    setEvents: function ($that) {
      var self = this;

      $that.nodes.$container.on('filterable_shades:selectSku', function (e, dataskuBaseId) {
        $that.results.skuBaseId = dataskuBaseId;
        self.loadResults($that);
      });
      $that.nodes.$container.off('filterable_shades:close_prd_card_results').on('filterable_shades:close_prd_card_results', function () {
        $that.nodes.$items.removeClass('shade-selected');
        $that.nodes.$prdCardResult.removeClass('result-displayed');
        if ($that.nodes.$overlayFindShade.length > 0) {
          $that.nodes.$overlayFindShade.removeClass('find-shade-filter-result-displayed');
        }
      });
      $that.nodes.$prdCardCloseBtn.once().on('click keydown', function(e) {
        if (e.key === 'Enter' || e.type === 'click') {
          $that.nodes.$container.trigger('filterable_shades:close_prd_card_results');
        }
      });
      $that.nodes.$vtoButton.once().on('click', function(event) {
        if ($that.nodes.$overlayFindShade.length > 0) {
          event.preventDefault();
          $(document).trigger('findyourshadepopup:close');
          $that.nodes.$shadeFinderResultParent.find('.js-product-vto-btn').trigger('click');
        }
      });

      $that.nodes.$foundationVtoButton.once().on('click', function(event) {
        if ($that.nodes.$overlayFindShade.length > 0) {
          event.preventDefault();
          $(document).trigger('findyourshadepopup:close');
          $that.nodes.$shadeFinderResultParent.find('.js-product-foundation-vto-btn').trigger('click');
        }
      });

      $(document).on('addToCart.success', function () {
        $(document).trigger('findyourshadepopup:close');
      });
    },
    loadResults: function ($that) {
      var skuBaseId = $that.results.skuBaseId;
      var skuData = prodcat.data.getSku('SKU' + skuBaseId);
      if (!$that.hasClass('js-product')) {
        $that.addClass('js-product');
      }
      $that.trigger('product.skuSelect', skuBaseId);

      if (!!skuData) {
        var shadeName = skuData.hasOwnProperty('SHADENAME') ? skuData.SHADENAME : '';
        var shadeDescription = skuData.hasOwnProperty('SHADE_DESCRIPTION')
        ? skuData.SHADE_DESCRIPTION
        : '';
        var skuColor = skuData.HEX_VALUE_STRING;
        var shadeNameArray = shadeName.split("\(");
        if(shadeName[1]) {
          shadeName = shadeNameArray[0] + '<span>\(' + shadeNameArray[1] + '</span>';
        }

        $that.attr('data-sku-base-id', skuBaseId);
        $that.nodes.$prdCardShadeName.html(shadeName);
        $that.nodes.$prdCardShadeDesc.html(shadeDescription);
        $that.nodes.$prdCardShadeSwatch.css('background-color', skuColor);
        if ($that.nodes.$prdCardPrdImage.hasClass('lazyload')) {
          // If the image was not loaded yet but has lazyload we need to update only data-src lazyload will set the src.
          if (
            !!skuData.IMAGE_MEDIUM_COMBINED &&
            skuData.IMAGE_MEDIUM_COMBINED[0] !== $that.nodes.$prdCardPrdImage.attr('data-src')
          ) {
            $that.nodes.$prdCardPrdImage.attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[0]);
          }
        } else {
          // If the image was already loaded by lazyload or lazyload is not used on this image.
          // We need to update both data-src and src.
          if (
            !!skuData.IMAGE_MEDIUM_COMBINED &&
            skuData.IMAGE_MEDIUM_COMBINED[0] !== $that.nodes.$prdCardPrdImage.attr('src')
          ) {
            $that.nodes.$prdCardPrdImage
              .attr('src', skuData.IMAGE_MEDIUM_COMBINED[0])
              .attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[0]);
          }
        }
      }
    },
  };
})(jQuery);
